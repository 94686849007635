@font-face {
  font-family: 'CircularPro';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/CircularPro-Book.woff2) format('woff2'), 
  url(../assets/fonts/CircularPro-Book.woff) format('woff');
}
@font-face {
  font-family: 'CircularPro';
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/CircularPro-Medium.woff2) format('woff2'), 
  url(../assets/fonts/CircularPro-Medium.woff) format('woff');
}
@font-face {
  font-family: 'CircularPro';
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/CircularPro-Bold.woff2) format('woff2'), 
  url(../assets/fonts/CircularPro-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tsstApxBaigK_hnnQ1iFow.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-agtnqWow.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  html[dir='ltr'] & {
    font-family: 'CircularPro',  sans-serif;
  }

  html[dir='rtl'] & {
    font-family: 'Almarai', sans-serif;
    font-weight: 500;
  }
}
