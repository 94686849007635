@import '../../styles/settings';

.textField {
  position: relative;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  &--margin-sm {
    margin-bottom: 0.625rem;
  }
  &--margin-lg {
    margin-bottom: 2.5rem;
  }
  &--margin-no {
    margin-bottom: 0 !important;
  }
  &__label {
    display: inline-block;
    margin-bottom: 6px;
    color: $gray-900;
    font-size: 14px;
  }
  &__input {
    display: block;
    width: 100%;
    padding: 14px;
    font-size: 14px;
    line-height: 1rem;
    color: $gray-900;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d2d2;
    outline: none;
    font-family: inherit;
    &::-webkit-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &::-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-ms-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:focus {
      outline: none;
    }
    &--lg {
      font-size: 2.0625em !important;
      font-weight: 500 !important;
      color: $gray-900 !important;
    }
    &--sm {
      padding: 0.75rem;
      font-size: 14px !important;
    }
    &--icon {
      html[dir="ltr"] & {
        padding-right: 40px;
      }
      html[dir="rtl"] & {
        padding-left: 40px;
      }

      + i {
        position: absolute;
        bottom: 15px;
        font-size: 1.25em;
        color: $gray-400;
        html[dir="ltr"] & {
          right: 15px;
        }
        html[dir="rtl"] & {
          left: 15px;
        }

        &.icon {
          &-sm {
            font-size: 0.625em;
          }
          &-xs {
            font-size: 0.5em;
            bottom: 17px;
          }
        }
      }
    }
  }
  &__validation {
    color: #ef2c2c;
    font-size: 12px;
  }
  &__arrow {
    font-weight: 600;
    html[dir='ltr'] & {
      margin-left: 8px;
      font-size: 1em;
      bottom: 16px;
    }
    html[dir='rtl'] & {
      margin-right: 8px;
      font-size: 1em;
      bottom: 16px;
    }
  }
  &__arrow-icon {
    font-weight: 600;
    html[dir='ltr'] & {
      margin-left: 8px;
      font-size: 7px;
      bottom: 16px;
    }
    html[dir='rtl'] & {
      margin-right: 8px;
      font-size: 7px;
      bottom: 16px;
    }
  }
}

.input-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  font-size: 0.875rem;
  &--icons {
    padding: 0.875rem 0.75rem;
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    color: $gray-400;
    min-width: 60px;
    text-align: center;
    font-size: 1.25em;
  }
  &__input-field {
    width: 100%;
    padding: 0.875rem 0.75rem;
    font-size: 1em;
    line-height: 1rem;
    color: $gray-900;
    border: 1px solid $border-color;
    &::-webkit-input-placeholder {
      color: $gray-300;
      font-family: inherit;
    }
    &::-moz-placeholder {
      color: $gray-300;
      font-family: inherit;
    }
    &:-ms-input-placeholder {
      color: $gray-300;
      font-family: inherit;
    }
    &:-moz-placeholder {
      color: $gray-300;
      font-family: inherit;
    }
    &:focus {
      outline: none;
    }
  }
}
