.header {
  font-size: 1rem;
  position: sticky;
  right: 0;
  left: 0;
  z-index: 999;
  top: 0;
  background-color: $white;
  transition: all 0.5s ease-in-out;
  
  &__top {
    background-color: $gray-600;
  }
  &__mob {
    background-color: white;
  }
  &__bottom {
    background-color: $white;

    & > .container {
      padding-left: rem(20);
      padding-right: rem(20);
    }
  }
  &__mobile {
    background-color: $primary;
    &--icons {
      font-size: rem(22);
      color: white;
      align-items: center;
    }
  }
  &__nav {
    &-item {
      padding-left: rem(15);
      padding-right: rem(15);
      font-size: rem(14);
      &:last-child {
        padding-right: 0;
      }
    }
  }
  &--landing {
    .header__bottom {
      border-bottom: 0;
      background-color: $white !important;
      border-bottom: solid 1px #ddd;
    }
    .container {
      max-width: 1364px !important;
      padding: 0 15px !important;
    }
  }
  .dropdown {
    .icon-student {
      color: #c4c4c4;
    }
  }
}

.header-mobile {
  background: url('../../images/mob-header-bg.png');
  h1 {
    font-size: rem(30);
  }
}

.header-mob-bottom {
  a {
    padding-bottom: 2px;
  }
}

.language-switch{
  background: #000;
  width: 54px;
  height: 30px;
  color: $primary;
  font-size: 11px;
  font-weight: bold;
  span{
    width: 27px;
    height: 30px;
    line-height: 31px;
    position: relative;
  }
  &::before{
    content: "";
    width: 26px;
    height: 26px;
    background-color: $white;
    border-radius: 50%;
    position: absolute;
    left: 2px;
  }
  &.en{
    .en{
      color: $gray-900;
    }
  }
  &.ar{
    .ar{
      color: $gray-900;
    }
    &::before{
      content: "";
      width: 26px;
      height: 26px;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      right: 26px;
    }
  }
}

.noti-count{
  width: 22px;
  height: 16px;
  line-height: 16px;
  font-family: $font-family-base;
  top: -8px;
  background: #FF4D00;
  html[dir="ltr"] & {
    right: -7px;
  }
  html[dir="rtl"] & {
    left: -7px;
  }
}

.mob-search{
  max-width: calc(100% - 28px);
  left: 14px;
  transform: translateY(50%);
  bottom: 0;
  padding: 6px;
}