$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$container-max-widths: (
  sm: 1330px,
);

$body-bg: #f2f2f2;
$primary: #FF0022;
$secondary: #6d6e70;
$gray-100: #EBEFF3;
$gray-200: #dedede;
$gray-300: #a4a4a4;
$gray-400: #BABABA;
$gray-500: #868686;
$gray-600: #808080;
$gray-700: #404040;
$gray-900: #202020;
$border-color: #b7b7b7;
$text-muted: $gray-300;
$white: #fff;

$font-family-sans-serif: 'CircularPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-arabic: 'CircularPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: 'CircularPro', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-size-base: 1rem;
$font-size-lg: 1.0625rem;
$font-size-sm: 0.9375rem;

$headings-color: #313131;

$border-radius-sm : 2px;

$input-border-color: #CFD6DD;

$shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
$shadow-sm: 0px 0px 4px rgba(0, 0, 0, 0.05);