@import '../../styles/settings';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.UserHeader {
  background-color: $primary;
  padding: rem(16) 0;
  &__container {
    max-width: 1230px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  &Row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    justify-content: space-between;
    @include media-breakpoint-down(xs) {
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  &__col {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(xs) {
      padding-left: 8px;
      padding-right: 8px;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      display: inline-flex;
      align-items: center;
    }
  }
  &__Logout {
    .Button {
      color: $white;
      text-transform: capitalize;
      @include media-breakpoint-down(xs) {
        font-size: 0;
        letter-spacing: 0;
      }
      i {
        font-size: rem(18);
        html[dir='ltr'] & {
          margin-right: rem(12);
          @include media-breakpoint-down(xs) {
            margin-right: 0;
          }
        }
        html[dir='rtl'] & {
          margin-left: rem(12);
          @include media-breakpoint-down(xs) {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__option-item {
    display: inline-flex;
    line-height: normal;
    position: relative;
    button {
      background: transparent;
      border: none;
      padding: 0;
      display: inline-flex;
      align-items: center;
    }
  }

  &__notification {
    display: inline-block;
    font-size: 1rem;

    &-box {
      display: flex;
      flex-direction: column;
      height: 450px;
      min-width: rem(340);
      overflow-y: scroll;
    }

    &-icon {
      font-size: rem(19);
      color: $white;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    &-header {
      font-size: 14px;
      font-weight: 500;
      padding: rem(17) rem(25);
      background-color: $white;
      position: relative;
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04);
      border-bottom: 1px solid $border-color;
    }

    &-footer {
      border-top: 1px solid $border-color;
      text-align: center;
      background-color: $white;
      padding: rem(18) 15px;
      margin-top: auto;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &-item {
      display: flex;
      padding: rem(14) rem(25);
      border-bottom: 1px solid $border-color;
      background-color: $white;
      &-icon {
        max-width: 36px;
        width: 100%;
        height: 36px;
        line-height: 36px;
        border-radius: 50%;
        background-color: #f6f6f6;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        html[dir='ltr'] & {
          margin-right: rem(10);
        }
        html[dir='rtl'] & {
          margin-left: rem(10);
        }
        i {
          font-size: rem(20);
          color: #bbb;
        }
      }
    }

    &-message {
      font-size: 13px;
      line-height: 20px;
      color: $gray-900;
    }

    &-time {
      font-size: 11px;
      color: $gray-400;
      html[dir='ltr'] & {
        margin-left: rem(5);
      }
      html[dir='rtl'] & {
        margin-right: rem(5);
      }
    }

    &--active {
      .UserHeader__notification-icon {
        &:before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: rgb(255, 132, 0);
          display: inline-block;
          position: absolute;
          top: 0;
          -webkit-box-shadow: 0 0 0 rgba(250, 140, 22, 0.9);
          -moz-box-shadow: 0 0 0 rgba(250, 140, 22, 0.9);
          box-shadow: 0 0 0 rgba(250, 140, 22, 0.9);
          animation: away 2s infinite;
          border: 2px solid $primary;

          html[dir='ltr'] & {
            right: -2px;
          }

          html[dir='rtl'] & {
            left: -2px;
          }
        }
      }
    }
  }

  &__languages {
    padding: 10px 0;
    margin: 0;
    background-color: $white;
  }

  &__language {
    text-decoration: none;
    color: $gray-400;
    display: flex;
    align-items: center;
    font-size: 13px;
    position: relative;
    padding: rem(8) rem(20);

    .dropdown__item {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .UserHeader__language-flag {
      html[dir='ltr'] & {
        margin-right: 10px !important;
      }

      html[dir='rtl'] & {
        margin-left: 10px !important;
      }
    }

    &-flag {
      width: 21px;
      height: auto;
      object-fit: cover;

      &--no-margin {
        margin: 0;
      }
    }

    &-chosen {
      position: relative;

      .UserHeader__language-flag {
        html[dir='ltr'] &,
        html[dir='rtl'] & {
          margin: 0;
        }
      }
    }
  }
}

@keyframes away {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
    box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
  }
}
.cursor-pointer {
  cursor: pointer;
}

.header{
  .header__nav{
    &-notification{
      font-size: rem(25);
      span{
        background-color: #FF4D00;
        font-size: 12px;
        min-width: 26px;
        text-align: center;
        color: $white;
        border-radius: 20px;
        left: 12px;
        padding: 2px;
      }
    }
    &-inner {
      font-size: rem(25);

      .align-text {
        position: relative;
        top: 1px;
      }

      .align-text-top {
        html[dir="ltr"] & {
          position: relative;
          top: -3px;
        }
      }
    }
  }
}

.brandSelection-popup{
  z-index: 1051;
  max-width: 603px;
  transition: all 0.3s ease-in-out;
  html[dir="ltr"] & {
    left: -603px;
  }
  html[dir="rtl"] & {
    right: -603px;
  }
  &.active{
    html[dir="ltr"] & {
      left: 0;
    }
    html[dir="rtl"] & {
      right: 0;
    }
  }
}

.brand-selected{
  height: 46px;
  padding: 0 14px;
  border: 1px solid #CFD6DD;
}

.brand-options{
  border: 1px solid #CFD6DD;
  z-index: 1;
  .brand-option{
    padding: 14px;
  }
}

.brand-select-info{
  background: #FFFBE7;
}

.branch-list{
  height: calc(100vh - 400px);
  &::-webkit-scrollbar {
      width: 6px;
  }

  &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #ebebeb;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #D4D8DD;
  }
  .branch-title{
    font-weight: 500;
  }
  .branches{
    transition: all 0.3s ease-in-out;
    &.active{
      box-shadow: inset 0 0 0 2px $primary;
      .branch-key{
        color: $primary !important;
      }
      .branch-title{
        color: $primary !important;
        font-weight: 700;
      }
    }
  }
}

.notification-list{
  li{
    &::before{
      content: "";
      width: 5px;
      height: 5px;
      background: #BDBDBD;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0px;
    }
    .time{
      background: #FBFBFB;
      border-radius: 2px;
      color: #BDBDBD;
    }
    &.unread{
      &::before{
        background: $primary;
      }
      .time{
        background: $primary;
        color: #fff;
      }
    }
  }
}

.notification-switch{
  .switch__grow{
    display: flex;
    align-items: center;
    label{
      margin: 0 rem(10) 0 0;
      color: #808080;
      font-size: 13px;
      font-weight: 400;
    }
    .switch {
      .slider{
        width: 29px;
        height: 17px;
        &::before{
          height: 11px;
          width: 11px;
          background: #828282;
          bottom: 2px;
        }
      }
      input:checked + .label + .slider:before {
        transform: translateX(13px);
        background: $white;
      }
    }
  }
}